button.anchor-like {
  background: transparent;
  border: 0;
  color: $primary;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: darken($primary, 30%);
  }
}

.box-app-version {
  font-size: 10px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  background-color: #fff;
  padding: 3px;
  z-index: 9999;
}
